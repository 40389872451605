/* eslint-disable max-lines */
import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { selectTestaniaName, selectFlowLink } from 'redux/testania/selectors';
import { takeLatest, put, select, call } from 'redux-saga/effects';

import api from 'apiSingleton';

import { selectUserId } from 'redux/user/selectors';
import { SET_IS_PAID } from 'redux/user/actionTypes';
import { showModal } from 'redux/uiEffects/actions';
import { RootState } from 'redux/types';
import { selectCurrentProduct, selectPaymentMethod } from 'redux/payment/selectors';
import * as actionTypes from 'redux/payment/actionTypes';
import {
    setPaymentType,
    setSubscriptionId,
    setValidatePaymentData,
    setValidatePaymentFail,
    validatePayment as validatePaymentAction,
} from 'redux/payment/actions';

import { CLP } from 'constants/payments/currency';
import { DEVICE_OS, PAYMENT_TYPES_NAME, NOTIFICATION_WINDOW_SET_UP_FOR_VALIDATE_FAILURE } from 'constants/payments';

import sentry from 'services/Sentry/SentryInstance';
import { PurchaseData } from 'services/Analytics/types';
import { pendingPurchase, purchase } from 'services/Analytics';

import {
    cleanObject,
    deleteSlash,
    fromPennyToInt,
    generateQueryParams,
    getDeviceOS,
    getPriceFromCents,
} from 'helpers/utils';
import { calculateLTV, getPurchaseOptions, getContentId } from 'helpers/payment/utils';
import { isLocalhost, replaceDataWithLocalhost } from 'helpers/environment';

import { ValidateResponse, ValidatePayload } from 'types/payments/validate';
import { CurrentProduct, PaymentMethod } from 'types/payments/payments';
import { IValidateData } from 'types/payments/paymentApiInterfaces';

const getUserId = (state: RootState) => selectUserId(state);

export const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);
export const testaniaName = (state: RootState) => selectTestaniaName(state);
export const getFlowLink = (state: RootState) => selectFlowLink(state);

export function* getAnalyticsData(payload: ValidatePayload) {
    const {
        amountWithoutCommission,
        introductorySubscriptionPrice,
        order: { amount, currency, subscription_id },
        brand,
    } = payload.data;

    const pageUrl = deleteSlash(payload.screenId);
    const product: CurrentProduct = yield select(getCurrentProduct);
    const userId: number = yield select(getUserId);
    const ab_test_name: string = yield select(testaniaName);
    const urlParams = generateQueryParams();
    const subscriptionPrice = introductorySubscriptionPrice && fromPennyToInt(introductorySubscriptionPrice);
    const productPrice = currency === CLP.name ? amount : fromPennyToInt(amount);

    const content_id = getContentId({
        product: product,
        price: getPriceFromCents(product.start_price),
        trialPrice: getPriceFromCents(product.price),
    });

    const paymentMethod: PaymentMethod = yield select(selectPaymentMethod);
    const paymentMethodName = PAYMENT_TYPES_NAME[paymentMethod] || null;

    return cleanObject({
        currency,
        value: amountWithoutCommission,
        content_id,
        subscription_price: subscriptionPrice,
        price: productPrice,
        payment: paymentMethodName,
        card_type: brand,
        user_id: userId,
        order_id: payload?.data?.order?.order_id,
        event_id: payload?.data?.order?.order_id,
        subscription_id: subscription_id,
        screen_id: pageUrl,
        tariff: product.id,
        ab_test_name,
        order_type: product?.payment_type,
        urlParams,
    });
}

export function* validate({ payload }: ReturnType<typeof validatePaymentAction>) {
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

    const { order } = payload.data;

    const currentProduct: CurrentProduct = yield select(getCurrentProduct);
    const { trial, period, payment_type, id, product_code, ltv, start_price } = currentProduct;
    const flow_link: string = yield select(getFlowLink);

    yield put(setPaymentType(payment_type));

    const deviceOS: string = getDeviceOS();
    const isTargetDevice = deviceOS === DEVICE_OS.ANDROID || deviceOS === DEVICE_OS.IOS;
    const platform = isTargetDevice ? deviceOS : DEVICE_OS.ANDROID;
    const isTrial = !!trial;
    const pageUrl = deleteSlash(payload.screenId);
    const ab_test_name: string = yield select(testaniaName);

    const meta: IValidateData = {
        order_id: order.order_id,
        subscription_id: order.subscription_id,
        payment_method: payload.paymentMethod,
        payment_type,
        product_id: id,
        product_code,
        platform,
        trial: isTrial,
        flow_link,
        ...(ab_test_name && { ab_test_name }),
        ...(pageUrl && { payment_screen: pageUrl }),
        ...(period && { subscription_period: period }),
    };

    // fix of local problem with SSRF
    isLocalhost() && replaceDataWithLocalhost(meta);

    try {
        const response: ValidateResponse = yield api.payment.postInit(meta);

        payload.data.amountWithoutCommission = calculateLTV(ltv, period);
        payload.data.isIntroductory = isTrial;
        payload.data.introductorySubscriptionPrice = start_price;

        if (!response.result) throw response;

        const analyticsData: PurchaseData = yield call(getAnalyticsData, payload);

        response.is_pending
            ? pendingPurchase(analyticsData)
            : purchase(analyticsData, {
                  ...getPurchaseOptions({ pageUrl, period, trial }),
              });

        yield put({ type: SET_IS_PAID, payload: true });
        yield put(setSubscriptionId(order?.subscription_id));
        yield put(setValidatePaymentData({ ...response, result: true }));
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    } catch (errorData) {
        if (errorData?.error) {
            console.error('error', errorData?.error);
            sentry.logError(errorData?.error, SENTRY_APP, ERROR_LEVELS.ERROR);
            yield put(setValidatePaymentFail(errorData?.error));
        }

        yield put(showModal(NOTIFICATION_WINDOW_SET_UP_FOR_VALIDATE_FAILURE));

        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    }
}

export const validatePayment = [takeLatest(actionTypes.VALIDATE_PAYMENT, validate)];
